(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

$(document).ready(function () {

    $('.to-cf').click(function () {
        $('html, body').animate({
            scrollTop: $('#footer-form').offset().top
        }, 700);
    });

    if (parseInt($(window).width()) <= 550) {
        var headerHeight = $('#header').height();
        $(window).on('scroll', function () {
            if ($(window).scrollTop() > headerHeight - 100) {
                $('.main-banner-section').css('position', 'fixed');
            } else {
                $('.main-banner-section').css('position', 'relative');
            }
        });
    }

    $('.close-btn').click(function () {
        $('.main-banner-section').remove();
    });
});

// ----------------------------------
var youtubeVideo = {
    videoBtn: '[data-videourl]',

    model: function model() {

        function videoinit() {
            $('body').on('click', youtubeVideo.videoBtn, function (event) {
                event.preventDefault();
                var videoSrc = $(this).data('videourl');

                var ID = '';
                var url = videoSrc.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
                if (url[2] !== undefined) {
                    ID = url[2].split(/[^0-9a-z_\-]/i);
                    ID = ID[0];
                } else {
                    ID = url;
                }

                var videoElement = $('<div class="video-popup-model">' + '<div class="video-layer">' + '<div class="video-model-close-layer">' + '</div>' + '<div class="model-wrapper">' + '<div class="videomodel">' + '<div class="videoscreen">' + '<iframe width="100%" height="auto" class="videlement"' + 'src="https://www.youtube.com/embed/' + ID + '?rel=0&amp;controls=1&amp;showinfo=0&amp;autoplay=1' + '" frameborder="0"' + 'allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"' + 'allowfullscreen></iframe>' + '</div>' + '<div class="modelCloseBtn">' + '</div>' + '</div>' + '</div>' + '</div>' + '</div>');

                $('body').prepend(videoElement);
                var videoWidth = $('.video-popup-model .videlement').width();
                var videHeight = 9 / 16 * videoWidth;
                $('.video-popup-model .videlement').height(videHeight);
                $('body').find('.video-popup-model').addClass('smooth_show');
            });
        }
        videoinit();

        function modelClose() {
            $('body').on('click', '.modelCloseBtn', function (event) {
                var model = $(this).parents('.video-popup-model');
                model.removeClass('smooth_show');
                setTimeout(function () {
                    model.remove();
                }, 500);
                $('body').removeClass('no-reload');
            });
        }
        modelClose();

        function modelLayerClose() {
            $('body').on('click', '.video-model-close-layer', function (event) {
                $(".modelCloseBtn").trigger('click');
            });
        }
        modelLayerClose();
    },
    init: function init() {
        youtubeVideo.model();
    }
};

youtubeVideo.init();

$('.video-banner').height($('.video-banner').width());
$(window).resize(function () {
    $('.video-banner').height($('.video-banner').width());
});

$('.slider').slick({
    slidesToShow: 1,
    arrows: false,
    fade: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnFocus: false,
    pauseOnHover: false
});

},{}]},{},[1]);
